body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide expand icon in goals dashboard if no addtl info to show. */
.hide-expandable > td div button span #expandable-button {
  visibility: hidden;
}

h1 {
  font-size: 3rem;
}
